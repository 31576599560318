import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Logo from './img/Logo.png';
import { BrowserRouter as Router, Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

const Navbar = () => {
  const [login, setLogin] = useState(false);
  const [nav, setNav] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  // Use effect to handle login status on component mount
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const userEmail = localStorage.getItem('userEmail'); // Assuming you store user email in localStorage
    //console.log(userEmail)
    const isAdminEmail = checkAdminEmail(userEmail); // Function to check if the user's email is an admin email
    setLogin(isLoggedIn);
    setIsAdmin(isAdminEmail);
    //handleNav();
    }, []); 

  // Logout handler
  const handleLogout = () => {
    const auth = getAuth();

    signOut(auth).then(() => {
        // Sign-out successful.
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userName');
        localStorage.removeItem('phoneNumber');
        
        setLogin(false); // Update the state to reflect the logout status
        setIsAdmin(false);
        navigate('/'); // Redirect to home or another page after logout
    }).catch((error) => {
        // An error happened.
        console.error('Error signing out:', error);
    });
}

  const checkAdminEmail = (email) => {
    const adminEmails = ['alamshahnawaz1913@gmail.com','ramakantapradhan615@gmail.com','himanshu.fastinfo@gmail.com','royalcarbbsr@gmail.com']; // Add your admin email addresses here
    return adminEmails.includes(email);
  }

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='bg-black flex justify-between items-center mx-auto text-white z-50 w-auto'>
      {/* Logo */}
      <Link to={'/'}>
        <img src={Logo} className='w-14 h-14 object-cover sm:mx-4' alt='logo' />
      </Link>
      
      {/* Desktop Navigation */}
      <ul className='hidden md:flex'>
        <Link to={'/'}>
          <li className='p-4 hover:text-green-700 rounded-xl m-2 cursor-pointer duration-300'>Home</li>
        </Link>
        <Link to={'/about'}>
          <li className='p-4 hover:text-green-700 rounded-xl m-2 cursor-pointer duration-300'>About Us</li>
        </Link>
        <a
          href="https://razorpay.me/@royal5075" 
          target="_blank"
          rel="noopener noreferrer"
          className="block p-4 hover:text-green-700 rounded-xl m-2 cursor-pointer duration-300"
        >Pay Now</a>
        <Link to={'/terms'}>
          <li className='p-4 hover:text-green-700 rounded-xl m-2 cursor-pointer duration-300'>Terms & Conditions</li>
        </Link>

        {isAdmin && (
          <Link to={'/admin'}>
            <li className='p-4 hover:text-green-700 rounded-xl m-2 cursor-pointer duration-300'>Admin Panel</li>
          </Link>
        )}
        {!login && (
          <Link to={'/loginsignup'}>
            <li className='p-4 hover:text-green-700 rounded-xl m-2 cursor-pointer duration-300'>Login/Signup</li>
          </Link>
        )}
        {login && (
          <li 
            className='p-4 hover:text-green-700 rounded-xl m-2 cursor-pointer duration-300' 
            onClick={handleLogout}
          >
            Logout
          </li>
        )}
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className='block md:hidden cursor-pointer'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? 'absolute md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 z-50'
            : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%] z-50'
        }
      >
        {/* Mobile Logo */}
        <Link to={'/'}>
        <img src={Logo} className='w-14 h-14 object-cover sm:mx-4 sm:my-4' alt='logo' />
      </Link>

        {/* Mobile Navigation Items */}
        <Link to={'/'}>
          <li className='p-4 border-b border-gray-600 hover:bg-[#00df9a] duration-300 hover:text-black cursor-pointer'>Home</li>
        </Link>
        <Link to={'/about'}>
          <li className='p-4 border-b border-gray-600 hover:bg-[#00df9a] duration-300 hover:text-black cursor-pointer'>About Us</li>
        </Link>
        <a
          href="https://razorpay.me/@royal5075"
          target="_blank"
          rel="noopener noreferrer"
          className='block p-4 border-b border-gray-600 hover:bg-[#00df9a] duration-300 hover:text-black cursor-pointer'
        >Pay Now</a>
        <Link to={'/terms'}>
          <li className='p-4 border-b border-gray-600 hover:bg-[#00df9a] duration-300 hover:text-black cursor-pointer'>Terms & Conditions</li>
        </Link>

        {isAdmin && (
          <Link to={'/admin'}>
            <li className='p-4 border-b border-gray-600 hover:bg-[#00df9a] duration-300 hover:text-black cursor-pointer'>Admin Panel</li>
          </Link>
        )}
        {!login && (
          <Link to={'/loginsignup'}>
            <li className='p-4 border-b border-gray-600 hover:bg-[#00df9a] duration-300 hover:text-black cursor-pointer'>
              Login/Signup
            </li>
          </Link>
        )}
        {login && (
          <li
            className='p-4 border-b border-gray-600 hover:bg-[#00df9a] duration-300 hover:text-black cursor-pointer'
            onClick={handleLogout}
          >
            Logout
          </li>
        )}
      </ul>
    </div>
  );
};

export default Navbar;
