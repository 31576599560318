import React from 'react'

export default function AboutUs() {
  return (
    <div>
        <h1 className='m-7 text-green-600 text-3xl font-semibold'>About Us</h1>
        <ul className='m-8'>
          <li className='text-lg'> <span className='font-bold'>Our Mission:</span> Drive Freedom and Convenience</li>
          <p className='text-slate-700'>At Royal Car, we believe that exploring a city should be a hassle-free and 
            enjoyable experience. Our mission is to provide you with the freedom to drive
             and explore Bhubaneswar at your own pace, without the limitations of 
             traditional car rental services. Whether you're a local resident or a 
             visitor, our self-drive car rentals empower you to embark on your journey 
             with confidence and comfort.</p>
        </ul>

        <ul className='m-8'>
          <li className='text-lg'> <span className='font-bold'>Who We Are:</span> Pioneers in Self-Drive Car Rentals</li>
          <p className='text-slate-700'>Established in 2024, we are a leading self-drive
           car rental service based in Bhubaneswar. Our team comprises experienced professionals
            passionate about travel and committed to delivering exceptional service. We offer 
            a diverse fleet of well-maintained vehicles, ensuring that you find the perfect 
            ride for your needs, be it a compact car for city tours or an SUV for exploring 
            the scenic countryside.</p>
        </ul>

        <ul className='m-8'>
          <li className='text-lg'> <span className='font-bold'>What We Offer:</span> Diverse Fleet for Every Journey</li>
          <p className='text-slate-700'>We take pride in our extensive range of 
          vehicles designed to cater to every type of journey. From economical 
          hatchbacks to luxurious sedans and robust SUVs, our fleet is equipped 
          with the latest models that guarantee safety, comfort, and style. Each 
          vehicle undergoes regular maintenance and sanitization to ensure a smooth 
          and worry-free ride.</p>
        </ul>

        
    </div>
  )
}
